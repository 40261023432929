.tab-enter {
  opacity: 0;
  transform: translateX(100%);
}

.tab-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.tab-exit {
  opacity: 1;
  transform: translateX(0);
}

.tab-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}
